
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ForgotPassword1View',
    components: {

    },
    data() {
        return {

        };
    },
    methods: {

    }
});
