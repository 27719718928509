class User
{
    id: number = 0;
    firstname: string = "";
    lastname: string = "";
    email: string = "";
    phone_number: string = "";
    admin: any = [];
}

export default User;