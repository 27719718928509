import Api from "../../api";
import AdminMenuResponse, {AdminAddMenuResponse} from "@/api/classes/AdminMenuResponse";
import ResponseTypes from "@/api/classes/Types";
import Store from "@/store/index";

class MenuService
{
    async addMenu(restaurant_id: number, category_id: number)
    {
        let res = new AdminAddMenuResponse();
        await Api.put("/admin/menus/create", {
                restaurant_id: restaurant_id,
                category_id: category_id
            },
            {
                headers: {"Authorization" : `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res.menu = response.data;
                res.status = ResponseTypes.SUCCESS;
            },
            (error) => {
                res.msg = error.response.data.msg;
            }
        );

        return res;
    }

    async getAllFromRestaurant(restaurant_id: number) : Promise<AdminMenuResponse>
    {
        let res = new AdminMenuResponse();
        await Api.post("/admin/menus/getAll", {
                restaurant_id: restaurant_id
            },
            {
                headers: {"Authorization" : `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res.categories = response.data;
                res.status = ResponseTypes.SUCCESS;
            },
            (error) => {
                res.msg = error.response.data.msg;
            }
        );

        return res;
    }

    async saveMenuData(restaurant_id: number, data: any)
    {
        let res = new AdminMenuResponse();
        await Api.post("/admin/menus/save", {
                restaurant_id: restaurant_id,
                data: data
            },
            {
                headers: {"Authorization" : `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res.status = ResponseTypes.SUCCESS;
                res.msg = "";
            },
            (error) => {
                res.msg = error.response.data.msg;
            }
        );

        return res;
    }

    async deleteMenu(restaurant_id: number, id: any)
    {
        let res = new AdminMenuResponse();
        await Api.delete("/admin/menus/delete", {
            headers: {
                "Authorization" : `Bearer ${Store.getters["user/getJwt"]}`
            },
            data: {
                restaurant_id: restaurant_id,
                id: id
            }
        }
        ).then(
            async (response) => {
                res.status = ResponseTypes.SUCCESS;
                res.msg = response.data.msg;
            },
            (error) => {
                res.msg = error.response.data.error;
            }
        );

        return res;
    }

    async getMenuById(restaurant_id: number, id: number)
    {
        let res = null;

        await Api.post("/admin/menus/getById", {
                id: id,
                restaurant_id: restaurant_id
            },
            {
                headers: {"Authorization" : `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res = response.data;
            },
            (error) => {
                res = error.response.data;
            }
        );

        return res;
    }
}

export default new MenuService();