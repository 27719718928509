
import {defineComponent, inject, ref, watch} from 'vue';

import {Icon} from "@vicons/utils";
import {KeyboardBackspaceRound} from "@vicons/material";
import {Delete20Regular, SpinnerIos20Filled} from "@vicons/fluent";
import MenuService from '@/api/services/Admin/MenuService';
import ResponseTypes from "@/api/classes/Types";
import {useToast} from "vue-toastification";
import EventBus from "@/events/EventBus";

interface MenuItemData {
    menu: null | any;
}

export default defineComponent({
    props: ["menuItem"],
    components: {
        Icon,
        KeyboardBackspaceRound,
        SpinnerIos20Filled,
        Delete20Regular
    },
    data()
    {
        return {
            menuParts: [] as any[],
            waitForSave: false,
            hasChanges: false
        }
    },
    setup(props)
    {
        let startItem = ref(null as any);
        let menu_item = ref(null as any);
        let selectedSize = ref(null as any);
        const eventBus = inject<EventBus>("$eventBus");

        function sizeChanged(event: any)
        {
            if(menu_item.value != null)
            {
                if(menu_item.value.menu_sizes != null)
                {
                    selectedSize.value = menu_item.value.menu_sizes[event.target.selectedIndex];
                    selectedSize.value.price = selectedSize.value.price as string;
                }
            }
        }

        watch(() => props.menuItem, (newVal) => {
            menu_item.value = newVal;
            if(menu_item.value != null)
            {
                startItem.value = JSON.parse(JSON.stringify(menu_item.value));

                if(menu_item.value.menu_sizes != null)
                    selectedSize.value = menu_item.value.menu_sizes[0];
            }
        })

        return {
            startItem,
            menu_item,
            selectedSize,
            sizeChanged,
            eventBus
        }
    },
    methods: {
        async save()
        {
            if(this.hasChanges && !this.waitForSave)
            {
                this.waitForSave = true;
                console.log(this.menu_item);
                let response = await MenuService.saveMenuData(4, this.menu_item);
                this.waitForSave = false;

                if(response.status === ResponseTypes.SUCCESS)
                {
                    this.menu_item = null;
                }
                else
                {
                    useToast().error(response.msg as string);
                }

                this.eventBus?.emit("adminMenuChanged");
            }
        },

        async deleteMenu()
        {
            if(!this.waitForSave)
            {
                this.waitForSave = true;
                let response = await MenuService.deleteMenu(4, this.menu_item.id);
                this.waitForSave = false;

                if(response.status === ResponseTypes.SUCCESS)
                {
                    this.menu_item = null;
                    useToast().success(response.msg as string);
                }
                else
                {
                    useToast().error(response.msg as string);
                }

                this.eventBus?.emit("adminMenuChanged");
            }
        },

        checkChanges()
        {
            const start = JSON.stringify(this.startItem);
            const current = JSON.stringify(this.menu_item);
            this.hasChanges = start !== current;
        },

        changePartArt(part: any, event: any)
        {
            part.art = event.target.value;
            this.checkChanges();
        },

        addIngredientToPart(part: any)
        {
            part.items.push({
                id: 0,
                menu_part_id: part.id,
                ingredient_id: 0,
                price: 0,
                can_changed: 1,
                is_selected: 0,
                ingredient: {
                    id: 0,
                    name: ""
                }
            });

            this.checkChanges();
        },

        addPart()
        {
            if(this.selectedSize !== null)
            {
                this.selectedSize.menu_parts.push({
                    art: "ONLY_ONE",
                    id: 0,
                    items: [],
                    menu_size_id: this.selectedSize.id,
                    name: "Neuer Bereich"
                });
            }
        },

        addSize()
        {
            if(this.menu_item !== null)
            {
                this.menu_item.menu_sizes.push({
                    id: 0,
                    menu_id: this.menu_item.id,
                    name: "neue Größe",
                    price: 8,
                    menu_parts: []
                });

                this.selectedSize = this.menu_item.menu_sizes[this.menu_item.menu_sizes.length - 1];
            }
        },

        deleteItemFromPart(part: any, item: any)
        {
            const itemIndex = part.items.indexOf(item);
            if(itemIndex >= 0)
                part.items.splice(itemIndex, 1);

            this.checkChanges();
        }
    }

});
