import { createStore } from 'vuex'

import userModule from './modules/user';
import restaurantModule from "./modules/restaurant";

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        user: userModule,
        restaurant: restaurantModule
    }
})
