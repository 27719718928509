import Api from "../api";
import RestaurantSearchResponse from "@/api/classes/RestaurantSearchResponse";
import ResponseTypes from "@/api/classes/Types";

class RestaurantSearchService
{
    async get(address: any) : Promise<RestaurantSearchResponse>
    {
        let res = new RestaurantSearchResponse();
        try {
            await Api.post("/restaurants/search", {
                address: address
            }).then(
                async (response) => {
                    console.log(response);
                    res.status = ResponseTypes.SUCCESS;
                    res.restaurants = response.data;
                },
                (error) => {
                    res.msg = error.response.data.msg;
                    console.log(error);
                }
            );
        }
        catch(e)
        {
            res.msg = "no_connection";
        }

        return res;
    }
}

export default new RestaurantSearchService();