import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';
import RootState from "@/store/types";
import Store from "@/store";

interface RestaurantState {
    address: any,
    delivery_mode: boolean
}

const mutations: MutationTree<RestaurantState> = {
    setAddress(state, payload)
    {
        localStorage.setItem("address", JSON.stringify(payload));
        state.address = payload;
    },
    setDeliveryMode(state, payload)
    {
        localStorage.setItem("delivery_mode", payload);
        state.delivery_mode = payload === 'true' || payload === true;
    }
};

const actions: ActionTree<RestaurantState, RootState> = {
    loadDataFromLocalStorage(context)
    {
        // Address
        let address = localStorage.getItem("address");
        if(address !== null)
        {
            let obj = JSON.parse(address);
            context.commit("setAddress", obj);
        }

        // Delivery mode
        let delivery_mode = localStorage.getItem("delivery_mode");
        if(delivery_mode !== null)
        {
            context.commit("setDeliveryMode", delivery_mode);
        }

        // Selected Restaurant

    },

    setAddress(context, payload)
    {
        context.commit("setAddress", payload);
    },

    setDeliveryMode(context, payload)
    {
        context.commit("setDeliveryMode", payload);
    }
};

const getters: GetterTree<RestaurantState, RootState> = {
    getAddress(state): any
    {
        return state.address;
    },

    getDeliveryMode(state): boolean
    {
        return state.delivery_mode ?? true;
    }
}

const restaurantModule: Module<RestaurantState, RootState> = {
    namespaced: true,
    state: {
        address: null,
        delivery_mode: false
    },
    mutations,
    actions,
    getters
};

export default restaurantModule;