
import { defineComponent } from 'vue';
import {Icon} from "@vicons/utils";
import {Search20Regular, SpinnerIos20Filled} from "@vicons/fluent";
import AuthService from '@/api/services/AuthService';
import AddressSearchService from "@/api/services/AddressSearchService";
import Store from "@/store";

export default defineComponent({
    name: 'HomeView',
    components: {
        Icon,
        Search20Regular,
        SpinnerIos20Filled
    },
    data() {
        return {
            delivery_address: "",
            addresses: [] as any,
            waitForInputChange: null as any,
            receivedAddresses: false,
            waitForAddressSearch: false,
            canSearchAddress: true,
            selectedAddress: null as any
        };
    },
    created()
    {
        let address = Store.getters["restaurant/getAddress"];
        if(address !== null && address !== undefined)
        {
            this.canSearchAddress = false;
            this.selectedAddress = address;
            this.delivery_address = address.street_name + " " + address.street_number + ", " + address.postal_code + " " + address.city;
            setTimeout(() => { this.canSearchAddress = true; }, 500);
        }
    },
    methods: {
        async SearchRestaurants(): Promise<void>
        {
            if(this.selectedAddress != null)
            {
                this.waitForAddressSearch = true;
                await Store.dispatch("restaurant/setAddress", this.selectedAddress);
                this.$router.push({name: 'search'});
            }
        },

        async updateSelectedAddress(obj: any): Promise<void>
        {
            this.selectedAddress = obj;
            this.canSearchAddress = false;
            this.addresses = [];
            this.receivedAddresses = false;
            this.delivery_address = obj.street_name + " " + obj.street_number + ", " + obj.postal_code + " " + obj.city;
            await Store.dispatch("restaurant/setAddress", this.selectedAddress);

            setTimeout(() => {
                this.canSearchAddress = true;
            }, 500);
        },

        searchAddress(): void
        {
            this.$router.push({name: "search"});
        },

        async timeOutEvent_CheckForInput()
        {
            if(this.delivery_address.length > 2)
            {
                this.addresses = await AddressSearchService.search(this.delivery_address);
                this.receivedAddresses = true;
            }
        }
    },

    watch: {
        delivery_address()
        {
            if(this.canSearchAddress)
            {
                localStorage.removeItem("address");
                this.selectedAddress = null;
                if(this.delivery_address.length < 3)
                {
                    this.receivedAddresses = false;
                    this.addresses = [];
                }

                clearTimeout(this.waitForInputChange);
                this.waitForInputChange = setTimeout(() => {
                    this.timeOutEvent_CheckForInput();
                }, 1250);
            }
        }
    }
});
