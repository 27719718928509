import Api from "../api";
import Store from "@/store";

class AddressSearchService
{
    async search(name: string)
    {
        console.log(name);
        let arr = [] as any;
        await Api.post("/search", {
            address: name
        }
        ).then(
            async (response) => {
                console.log(response);
                arr = response.data;
            },
            (error) => {
                console.log(error);
            }
        );

        return arr;
    }
}

export default new AddressSearchService();