import Api from "../../api";
import ResponseTypes from "@/api/classes/Types";
import Store from "@/store/index";
import CategoryResponse from "@/api/classes/CategoryResponse";

class CategoryService
{
    async get(restaurant_id: number, category_id: number)
    {
        let res = null as any;
        await Api.post("/admin/category/get/" + category_id, {
                restaurant_id: restaurant_id
            },
            {
                headers: {"Authorization": `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res = response.data;
            },
            (error) => {
                console.log(error);
                res = null;
            }
        );

        return res;
    }

    async create(restaurant_id: number)
    {
        let res = new CategoryResponse();
        await Api.put("/admin/category/create", {
                restaurant_id: restaurant_id
            },
            {
                headers: {"Authorization": `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res.category = response.data;
                res.status = ResponseTypes.SUCCESS;
            },
            (error) => {
                res.msg = error.response.data.error;
            }
        );

        return res;
    }

    async save(restaurant_id: number, category: any)
    {
        let res = new CategoryResponse();
        await Api.post("/admin/category/save", {
                restaurant_id: restaurant_id,
                category: category
            },
            {
                headers: {"Authorization": `Bearer ${Store.getters["user/getJwt"]}`}
            }
        ).then(
            async (response) => {
                res.category = response.data;
                res.status = ResponseTypes.SUCCESS;
            },
            (error) => {
                console.log(error);
                res.msg = error.response.data.error;
            }
        );

        return res;
    }
}

export default new CategoryService();