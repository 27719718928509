import { reactive } from 'vue';

interface Event {
    name: string;
    data?: any;
}

export default class EventBus {
    private listeners: { [eventName: string]: ((event: Event) => void)[] } = reactive({});

    public on(eventName: string, callback: (event: Event) => void): void {
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(callback);
    }

    public off(eventName: string, callback?: (event: Event) => void): void {
        if (!this.listeners[eventName]) {
            return;
        }
        if (!callback) {
            delete this.listeners[eventName];
            return;
        }
        const index = this.listeners[eventName].indexOf(callback);
        if (index !== -1) {
            this.listeners[eventName].splice(index, 1);
        }
    }

    public emit(eventName: string, data?: any): void {
        if (!this.listeners[eventName]) {
            return;
        }
        const event: Event = { name: eventName, data };
        this.listeners[eventName].forEach(callback => callback(event));
    }
}