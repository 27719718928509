import ResponseTypes from "@/api/classes/Types";

class AuthResponse
{
    status: ResponseTypes = ResponseTypes.ERROR;
    msg: string|null = "";
    redirection: boolean = false;
    redirection_routeName: string|null = "";
}

export default AuthResponse;