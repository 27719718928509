
import {defineComponent} from 'vue';

import {Icon} from '@vicons/utils';
import {SpinnerIos20Filled} from "@vicons/fluent";
import {ErrorFilled} from "@vicons/material";
import AuthService from "@/api/services/AuthService";
import AuthResponse from "@/api/classes/AuthResponse";
import {useToast} from "vue-toastification";

export default defineComponent({
    name: 'RegisterView',
    components: {
        Icon,
        SpinnerIos20Filled,
        ErrorFilled
    },
    data() {
        return {
            waitForRegisterResponse: false,
            registerResponse: null as AuthResponse|null,
            firstname: "",
            lastname: "",
            email: "",
            phone_number: "",
            password1: "",
            password2: ""
        };
    },
    methods: {
        async register() {
            this.registerResponse = null;
            
            this.waitForRegisterResponse = true;
            this.registerResponse = await AuthService.register(this.firstname, this.lastname, this.email, this.phone_number, this.password1, this.password2);
            this.waitForRegisterResponse = false;

            if(this.registerResponse.status === 1)
            {
                useToast().success(this.registerResponse.msg as string);

                setTimeout(() => {
                    this.$router.push({name: 'home'});
                }, 100);
            }
        }
    }
});
