import RestaurantResponse from "@/api/classes/RestaurantResponse";
import Api from "@/api/api";
import Store from "@/store";
import ResponseTypes from "@/api/classes/Types";

class RestaurantService
{
    async getRestaurant(id: number) : Promise<RestaurantResponse>
    {
        let responseItem = new RestaurantResponse();
        try {
            await Api.post("/restaurant", {
                id: id,
                address: Store.getters["restaurant/getAddress"]
            }).then(
                async (response) => {
                    responseItem.restaurant = response.data;
                    responseItem.status = ResponseTypes.SUCCESS;
                },
                (error) => {
                    responseItem.status = ResponseTypes.ERROR;
                    responseItem.msg = "Es ist ein unbekannter Fehler aufgetreten.";
                }
            );
        }
        catch(e)
        {
            responseItem.status = ResponseTypes.NO_CONNECTION;
            responseItem.msg = "Es konnte keine Verbindung zum Server hergestellt werden.";
        }

        return responseItem;
    }
}

export default new RestaurantService();