import ResponseTypes from "@/api/classes/Types";

class AdminMenuResponse
{
    status: ResponseTypes = ResponseTypes.ERROR;
    msg: string|null = "";
    categories: any = [];
}

export class AdminAddMenuResponse
{
    status: ResponseTypes = ResponseTypes.ERROR;
    msg: string|null = "";
    menu: any;
}

export default AdminMenuResponse;