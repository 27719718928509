
import {defineComponent, inject} from 'vue';

import {Icon} from "@vicons/utils";
import {SpinnerIos20Filled} from "@vicons/fluent";
import {ErrorFilled} from "@vicons/material";
import { useToast } from "vue-toastification";
import AuthService from "@/api/services/AuthService";
import store from '../../store/index';
import AuthResponse from '@/api/classes/AuthResponse';
import EventBus from "@/events/EventBus";
const ResponseTypes = require("@/api/classes/Types");

export default defineComponent({
    name: 'LoginView',
    components: {
        Icon,
        SpinnerIos20Filled,
        ErrorFilled
    },
    setup()
    {
        const eventBus = inject<EventBus>("$eventBus");
        return {
            eventBus
        }
    },
    data() {
        return {
            email: "",
            password: "",
            waitForLoginResponse: false,
            loginResponse: null as AuthResponse | null
        };
    },
    created()
    {

    },
    methods: {
        async Login()
        {
            this.loginResponse = null;
            this.waitForLoginResponse = true;
            this.loginResponse = await AuthService.login(this.email, this.password);
            if(this.loginResponse.status === 1)
            {
                useToast().success(this.loginResponse.msg as string);

                this.eventBus?.emit("userStateChanged", true);

                setTimeout(() => {
                    this.$router.push({name: 'home'});
                }, 100);
            }
            this.waitForLoginResponse = false;
        }
    },
    computed: {
        ResponseTypes()
        {
            return ResponseTypes;
        }
    }
});
