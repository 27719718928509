import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "deliverySwitch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "review" }
const _hoisted_4 = { class: "info_block" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "infos" }
const _hoisted_7 = {
  key: 0,
  class: "info"
}
const _hoisted_8 = { class: "info" }
const _hoisted_9 = {
  key: 0,
  class: "infos"
}
const _hoisted_10 = { class: "info" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "review" }
const _hoisted_13 = { class: "info_block" }
const _hoisted_14 = { class: "name" }
const _hoisted_15 = { class: "infos" }
const _hoisted_16 = { class: "info" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "infos" }
const _hoisted_20 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delivery = _resolveComponent("Delivery")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DirectionsWalkFilled = _resolveComponent("DirectionsWalkFilled")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_StarRateSharp = _resolveComponent("StarRateSharp")!
  const _component_Backpack20Regular = _resolveComponent("Backpack20Regular")!
  const _component_Clock20Regular = _resolveComponent("Clock20Regular")!
  const _component_MapMarkerAlt = _resolveComponent("MapMarkerAlt")!
  const _component_RestaurantSearchLoad = _resolveComponent("RestaurantSearchLoad")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "navTop" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["option", {active: _ctx.deliverySearchMode}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ChangeDeliveryMode(true)))
            }, [
              _createVNode(_component_Icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Delivery)
                ]),
                _: 1
              }),
              _createTextVNode(" Lieferung ")
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["option", {active: !_ctx.deliverySearchMode}]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangeDeliveryMode(false)))
            }, [
              _createVNode(_component_Icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DirectionsWalkFilled)
                ]),
                _: 1
              }),
              _createTextVNode(" Abholung ")
            ], 2)
          ])
        ]),
        _: 1
      }),
      (_ctx.finishedSearch && _ctx.deliverySearchMode)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("h2", null, "Restaurants (" + _toDisplayString(_ctx.restaurants_delivery.length) + ")", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurants_delivery, (restaurant) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "searchRestaurant",
                  onClick: ($event: any) => (_ctx.OpenRestaurant(restaurant))
                }, [
                  _createElementVNode("div", {
                    class: "background_img",
                    style: _normalizeStyle({ 'background-image': 'url(./img/restaurants/title/' + restaurant.background_path + ')' })
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["logo", {dark: !restaurant.is_light}])
                    }, [
                      _createElementVNode("div", {
                        class: "img",
                        style: _normalizeStyle({ 'background-image': 'url(./img/restaurants/logos/' + restaurant.logo_path + ')' })
                      }, null, 4)
                    ], 2),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_StarRateSharp)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" toDo ")
                    ])
                  ], 4),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(restaurant.name), 1),
                    _createElementVNode("div", _hoisted_6, [
                      (restaurant.min_order_price !== undefined)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_Icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Backpack20Regular)
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" ab " + _toDisplayString(restaurant.min_order_price.toFixed(2).replace(".", ",")) + " € ", 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_Icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Clock20Regular)
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" toDo Minuten ")
                      ])
                    ]),
                    (restaurant.delivery_price !== undefined)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_Icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Delivery)
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(restaurant.delivery_price == 0 ? "kostenlos" : restaurant.delivery_price.toFixed(2).replace(".", ",") + "€"), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ], 8, _hoisted_2))
              }), 256))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.finishedSearch && !_ctx.deliverySearchMode)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("h2", null, "Restaurants (" + _toDisplayString(_ctx.restaurants_takeaway.length) + ")", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurants_takeaway, (restaurant) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "searchRestaurant",
                  onClick: ($event: any) => (_ctx.OpenRestaurant(restaurant))
                }, [
                  _createElementVNode("div", {
                    class: "background_img",
                    style: _normalizeStyle({ 'background-image': 'url(./img/restaurants/title/' + restaurant.background_path + ')' })
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["logo", {dark: !restaurant.is_light}])
                    }, [
                      _createElementVNode("div", {
                        class: "img",
                        style: _normalizeStyle({ 'background-image': 'url(./img/restaurants/logos/' + restaurant.logo_path + ')' })
                      }, null, 4)
                    ], 2),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_Icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_StarRateSharp)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" todo ")
                    ])
                  ], 4),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(restaurant.name), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_Icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_MapMarkerAlt)
                          ]),
                          _: 1
                        }),
                        (restaurant.city === restaurant.location || restaurant.location === null)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(restaurant.postal_code) + " " + _toDisplayString(restaurant.city) + ", " + _toDisplayString(restaurant.street_name) + " " + _toDisplayString(restaurant.house_number), 1))
                          : _createCommentVNode("", true),
                        (restaurant.city !== restaurant.location)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(restaurant.postal_code) + " " + _toDisplayString(restaurant.city) + "-" + _toDisplayString(restaurant.location) + ", " + _toDisplayString(restaurant.street_name) + " " + _toDisplayString(restaurant.house_number), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_Icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Clock20Regular)
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" toDo Minuten ")
                      ])
                    ])
                  ])
                ], 8, _hoisted_11))
              }), 256))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.finishedSearch)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_RestaurantSearchLoad)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}