
import {defineComponent, inject, ref, watch} from 'vue';

import {Icon} from "@vicons/utils";
import {KeyboardBackspaceRound} from "@vicons/material";
import {Delete20Regular, SpinnerIos20Filled} from "@vicons/fluent";
import MenuService from '@/api/services/Admin/MenuService';
import ResponseTypes from "@/api/classes/Types";
import {useToast} from "vue-toastification";
import EventBus from "@/events/EventBus";
import CategoryService from "@/api/services/Admin/CategoryService";

export default defineComponent({
    props: ["categoryItem"],
    components: {
        Icon,
        KeyboardBackspaceRound,
        SpinnerIos20Filled,
        Delete20Regular
    },
    data()
    {
        return {
            waitForSave: false,
            hasChanges: false
        }
    },
    setup(props)
    {
        let startItem = ref(null as any);
        let category = ref(null as any);
        const eventBus = inject<EventBus>("$eventBus");


        watch(() => props.categoryItem, (newVal) => {
            category.value = newVal;
            if(category.value != null)
            {
                startItem.value = JSON.parse(JSON.stringify(category.value));
            }
        })

        return {
            startItem,
            eventBus,
            category
        }
    },
    methods: {
        checkChanges()
        {
            const start = JSON.stringify(this.startItem);
            const current = JSON.stringify(this.category);
            this.hasChanges = start !== current;
        },

        async save()
        {
            if(!this.waitForSave)
            {
                this.waitForSave = true;
                let res = await CategoryService.save(4, this.category);
                if(res.status === ResponseTypes.ERROR)
                {
                    useToast().success(res.msg as string);
                }
                else
                {
                    this.eventBus?.emit("adminMenuChanged");
                    this.category = null;
                }
                this.waitForSave = false;
            }
        }
    }

});
