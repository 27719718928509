
import {defineComponent} from 'vue';

import RestaurantSearchLoad from "@/views/_components/loads/RestaurantSearchLoad.vue";

import {Icon} from '@vicons/utils';
import {MapMarkerAlt} from "@vicons/fa";
import {StarRateSharp} from "@vicons/material";
import {Backpack20Regular} from '@vicons/fluent';
import {Clock20Regular} from "@vicons/fluent";
import {Delivery} from "@vicons/carbon";
import {DirectionsWalkFilled} from "@vicons/material";
import Store from "@/store";
import RestaurantSearchService from '@/api/services/RestaurantSearchService';
import {useToast} from "vue-toastification";

export default defineComponent({
    name: 'SearchView',
    components: {
        RestaurantSearchLoad,
        Icon,
        Backpack20Regular,
        Clock20Regular,
        Delivery,
        StarRateSharp,
        DirectionsWalkFilled,
        MapMarkerAlt
    },

    data() {
        return {
            finishedSearch: false,
            deliverySearchMode: false,
            address: null as any,

            restaurants_takeaway: [] as any,
            restaurants_delivery: [] as any
        };
    },
    created() {
        this.address = Store.getters["restaurant/getAddress"];
        this.deliverySearchMode = Store.getters["restaurant/getDeliveryMode"];

        if (this.address == null) {
            this.$router.push({name: 'home'});
        }

        this.GetRestaurants().then();
    },
    methods: {
        async ChangeDeliveryMode(mode: boolean): Promise<void> {
            this.deliverySearchMode = mode;
            await Store.dispatch("restaurant/setDeliveryMode", mode);
        },

        fillRestaurants(restaurantData: any): void {
            this.restaurants_delivery = [];
            this.restaurants_takeaway = [];

            for (let i = 0; i < restaurantData.length; i++) {
                if(restaurantData[i].is_open)
                {
                    if (restaurantData[i].delivery === true) {
                        this.restaurants_delivery.push(restaurantData[i]);
                    }

                    if (restaurantData[i].takeaway === true) {
                        this.restaurants_takeaway.push(restaurantData[i]);
                    }
                }
            }
        },

        async GetRestaurants() {
            let data = await RestaurantSearchService.get(this.address);
            if (data.status !== 1) {
                useToast().error(data.msg as string);
                this.$router.push({name: 'home'});
            }

            this.fillRestaurants(data.restaurants);
            this.finishedSearch = true;
        },

        OpenRestaurant(restaurant: any)
        {
            this.$router.push({name: 'restaurant', params: {
                id: restaurant.id,
                name: restaurant.name
            }});
        }
    }
});
