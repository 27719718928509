import any = jasmine.any;

class RestaurantHelper
{
    public static createCategoryStrings(categories: []): []
    {
        categories.forEach(function(category: any)
        {
            category.mspi.forEach(function(menu: any)
            {
                // Ingredients
                if(menu.menu_sizes.length > 0)
                {
                    let size = menu.menu_sizes[0];
                    if(size.menu_parts.length > 0)
                    {
                        let menu_ingredients_str = "";
                        let part = size.menu_parts[0];
                        if(part.art === "MULTIPLE")
                        {
                            part.items.forEach(function(item: any)
                            {
                                if(item.is_selected)
                                    menu_ingredients_str += item.ingredient.name + ", ";
                            });
                            if(menu_ingredients_str.length > 0)
                                menu_ingredients_str = menu_ingredients_str.substring(0, menu_ingredients_str.length - 2);
                        }
                        else if(part.art == "ONLY_ONE")
                        {

                        }

                        if(menu_ingredients_str.length > 0)
                        {
                            menu.menu_ingredients_str = menu_ingredients_str;
                        }
                    }
                }

                // Sizes
                if(menu.menu_sizes.length > 1)
                {
                    let str = "";
                    menu.menu_sizes.forEach(function(size: any){
                        str += size.name + ", ";
                    });
                    menu.menu_size = str.substring(0, str.length - 2);
                }
            });
        });
        return categories;
    }
}

export default RestaurantHelper;