
import {defineComponent, inject } from "vue";
import { mapGetters } from 'vuex';
import AuthService from "@/api/services/AuthService";
import Store from "@/store/index";
import { useToast } from "vue-toastification";
import EventBus from "@/events/EventBus";

// Icons
import { MenuRound } from '@vicons/material'
import { AdminPanelSettingsOutlined } from "@vicons/material";
import { UserOutlined } from "@vicons/antd";
import {Delivery} from "@vicons/carbon";
import { Icon } from '@vicons/utils'
import {useRoute, useRouter } from "vue-router";

export default defineComponent( {
    components: {
        Icon,
        MenuRound,
        UserOutlined,
        Delivery,
        AdminPanelSettingsOutlined
    },

    setup()
    {
        const eventBus = inject<EventBus>("$eventBus");
        return {
            eventBus
        }
    },

    async created()
    {
        // load data from local storage
        await Store.dispatch("user/loadDataFromLocalStorage");
        await Store.dispatch("restaurant/loadDataFromLocalStorage");

        await AuthService.check();
        this.eventBus?.emit("userStateChanged", true);
        this.refreshRoute();

        this.eventBus?.on("userStateChanged", (event: any) => {
            this.refreshRoute();
        });
    },

    data() {
        return {
            openNavigation: false,
            isAdmin: false,
            startRoute: "",
            startRouteParams: [],
            isInitRoute: true
        }
    },

    methods: {
        TriggerNavigation() {
            this.openNavigation = !this.openNavigation;
        },
        async logout()
        {
            await AuthService.logout();
            useToast().success("Du hast dich erfolgreich ausgeloggt.");
            this.eventBus?.emit("userStateChanged", false);
            this.isAdmin = false;
            this.openNavigation = false;
        },
        refreshRoute()
        {
            this.isAdmin = Store.getters["user/getUser"] != null ? Store.getters["user/getUser"].admin.length > 0 : false;

            if(!Store.getters["user/getIsFinishedFirstRoute"])
            {
                const routeName = Store.getters["user/getFirstRoute"];
                const routeParams = Store.getters["user/getFirstRouteParams"];
                this.$router.push({ name: routeName, params: routeParams });
            }
            else
            {
                this.$router.push({ query: { _r: Date.now() }});
            }
        }
    },

    computed: {
        ...mapGetters('user', ['getUser']),
        user() : any {
            return this.getUser;
        }
    }
});
