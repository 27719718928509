import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';
import User from "@/models/User";
import RootState from "@/store/types";

interface UserState {
    user: User | undefined | null;
    jwt: string | null;

    isFirstAddressCall: boolean;
    firstAddressCall: string | undefined | null;
    firstAddressCallParams: [];
    isFinishedFirstCall: boolean;
}

const mutations: MutationTree<UserState> = {
    reset(state)
    {
        state.user = null;
    },
    set(state, payload)
    {
        if(payload == null)
        {
            state.user = null;
            return;
        }

        state.user = new User();
        state.user.id = payload.id;
        state.user.firstname = payload.firstname;
        state.user.lastname = payload.lastname;
        state.user.email = payload.email;
        state.user.phone_number = payload.phone_number;
        state.user.admin = payload.admin;

        console.log(state.user);
    },
    setJwt(state, payload)
    {
        state.jwt = payload;

        if(state.jwt != null)
        {
            localStorage.setItem('jwt', JSON.stringify({
                jwt: state.jwt
            }));
        }
        else
        {
            localStorage.removeItem("jwt");
        }
    },
    setFirstRoute(state, payload)
    {
        state.isFirstAddressCall = false;
        state.firstAddressCall = payload.name;
        state.firstAddressCallParams = payload.params;
        state.isFinishedFirstCall = false;
    }
};
const actions: ActionTree<UserState, RootState> = {
    loadDataFromLocalStorage(context)
    {
        const storedData = localStorage.getItem('jwt');
        if (storedData) {
            try {
                let data = JSON.parse(storedData);
                context.commit('setJwt', data.jwt);
            }
            catch (e)
            {
                localStorage.removeItem('jwt');
            }
        }
    },
    setUser(context, payload)
    {
        context.commit("set", payload);
    },
    setJwt(context, payload)
    {
        context.commit("setJwt", payload);
    },
    setFirstRoute(context, payload)
    {
        context.commit("setFirstRoute", payload);
    }
};
const getters: GetterTree<UserState, RootState> = {
    getUser(state) : User | undefined | null
    {
        return state.user;
    },
    getJwt(state) : string|null
    {
        return state.jwt;
    },
    getFirstRoute(state)
    {
        state.isFinishedFirstCall = true;
        return state.firstAddressCall;
    },
    getFirstRouteParams(state)
    {
        return state.firstAddressCallParams;
    },
    getIsFirstRoute(state)
    {
        return state.isFirstAddressCall;
    },
    getIsFinishedFirstRoute(state)
    {
        return state.isFinishedFirstCall;
    }
};

const userModule: Module<UserState, RootState> = {
    namespaced: true,
    state: {
        user: null,
        jwt: null,
        firstAddressCall: null,
        firstAddressCallParams: [],
        isFirstAddressCall: true,
        isFinishedFirstCall: false
    },
    mutations,
    actions,
    getters,
};

export default userModule;