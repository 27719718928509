
import {defineComponent} from 'vue';
import Store from "@/store";
import RestaurantHelper from "@/helpers/RestaurantHelper";

import {Icon} from '@vicons/utils';
import {DirectionsWalkFilled, KeyboardBackspaceRound, StarRateSharp} from "@vicons/material";
import {Backpack20Regular, Clock20Regular, ShoppingBag20Regular} from '@vicons/fluent';
import {Delivery} from "@vicons/carbon";
import {Info, MapMarkerAlt, Minus, Plus} from "@vicons/fa";
import RestaurantService from "@/api/services/RestaurantService";
import ResponseTypes from "@/api/classes/Types";

export default defineComponent({
    name: 'RestaurantView',
    components: {
        Icon,
        Backpack20Regular,
        Clock20Regular,
        Delivery,
        StarRateSharp,
        DirectionsWalkFilled,
        ShoppingBag20Regular,
        KeyboardBackspaceRound,
        Plus,
        Minus,
        MapMarkerAlt,
        Info
    },
    data()
    {
        return {
            openCart: false,
            restaurant: null as any,
            delivery_mode: Store.getters["restaurant/getDeliveryMode"],
            showInfo: false,
            configMenu: null as any,
            price: 0
        }
    },

    async created()
    {
        let restaurant_id = parseInt(this.$route.params["id"] as string);
        let response = await RestaurantService.getRestaurant(restaurant_id);
        if(response.status === ResponseTypes.SUCCESS)
        {
            this.restaurant = response.restaurant;
            if(this.restaurant !== null)
            {
                if(this.restaurant.categories != null)
                {
                    this.restaurant.categories = RestaurantHelper.createCategoryStrings(this.restaurant.categories);
                }

            }
        }
    },
    methods: {
        addMenuToCart(menu: any)
        {
            this.configMenu = menu;
            if(this.configMenu != null)
            {
                if(this.configMenu.menu_size.length > 0)
                {
                    this.configMenu.selectedSize = JSON.parse(JSON.stringify(menu.menu_sizes[0]));
                }

            }
            this.recalculatePrice();
        },
        sizeChanged(event: any)
        {
            if(this.configMenu != null)
            {
                this.configMenu.selectedSize = JSON.parse(JSON.stringify(this.configMenu.menu_sizes[event.target.selectedIndex]));
            }

            this.recalculatePrice();
        },
        partItemSelectionChanged(part: any, event: any)
        {
            if(this.configMenu != null)
                if(this.configMenu.selectedSize != null)
                {
                    let getPartIndex = this.configMenu.selectedSize.menu_parts.indexOf(part);
                    let configPart = this.configMenu.selectedSize.menu_parts[getPartIndex];

                    if(configPart.art === "ONLY_ONE")
                    {
                        let partItem = configPart.items[event.target.selectedIndex];
                        configPart.items.forEach(function(item: any){
                            item.is_selected = false;
                        });
                        partItem.is_selected = true;
                    }
                }

            this.recalculatePrice();
        },
        partItemCheckChanged(part: any, item: any, event: any)
        {
            if(this.configMenu != null)
                if(this.configMenu.selectedSize != null)
                {
                    let getPartIndex = this.configMenu.selectedSize.menu_parts.indexOf(part);
                    let configPart = this.configMenu.selectedSize.menu_parts[getPartIndex];

                    let getItemIndex = configPart.items.indexOf(item);
                    let configItem = configPart.items[getItemIndex];

                    if(configItem.can_changed)
                        configItem.is_selected = event.target.checked;
                }
            this.recalculatePrice();
        },
        recalculatePrice()
        {
            let price = 0 as number;
            if(this.configMenu != null)
                if(this.configMenu.selectedSize != null)
                {
                    price += this.configMenu.selectedSize.price;

                    this.configMenu.selectedSize.menu_parts.forEach(function(part: any)
                    {
                        part.items.forEach(function(item: any)
                        {
                            if(item.is_selected)
                                price += item.price as number;
                        });
                    });
                }

            this.price = price;
        }
    }
});
