
import { defineComponent } from 'vue';
import Api from "@/api/api";

import {Icon} from '@vicons/utils';
import {Clock20Regular} from "@vicons/fluent";
import {ShoppingBag20Regular} from "@vicons/fluent";
import {Money20Regular} from "@vicons/fluent";
import Store from "@/store";
import ResponseTypes from "@/api/classes/Types";

export default defineComponent({
    name: 'MyOrdersView',
    components: {
        Icon,
        Clock20Regular,
        ShoppingBag20Regular,
        Money20Regular
    },
    data() {
        return {

        };
    },
    methods: {
        async Test()
        {
            let jwt = Store.getters["user/getJwt"];

            await Api.get("/profile", {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }).then(
                async (response) => {
                    console.log(response);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    },

    created()
    {
        this.Test();
    }
});
