
import {defineComponent, inject, watch} from 'vue';
import EventBus from "@/events/EventBus";
import AdminMenuResponse from "@/api/classes/AdminMenuResponse";
import MenuService from "@/api/services/Admin/MenuService";
import RestaurantHelper from '@/helpers/RestaurantHelper';

import EditRestaurantCategoryModal from "@/views/_components/admin/modals/EditRestaurantCategoryModal.vue";
import EditMenuModal from "@/views/_components/admin/modals/EditMenuModal.vue";
import ResponseTypes from "@/api/classes/Types";
import {useToast} from "vue-toastification";

import {Icon} from "@vicons/utils";
import {SpinnerIos20Filled} from "@vicons/fluent";
import CategoryService from '@/api/services/Admin/CategoryService';


export default defineComponent({
    name: 'ShowMenusView',
    components: {
        EditMenuModal,
        EditRestaurantCategoryModal,
        Icon,
        SpinnerIos20Filled
    },
    setup()
    {
        const eventBus = inject<EventBus>("$eventBus");
        return {
            eventBus
        }
    },
    data() {
        return {
            restaurant_id: 4,
            categories: [],
            waitForMenusResponse: true,
            menuResponse: null as AdminMenuResponse | null,
            editMenuItem: null as any,
            loadEditMenuItem: false,
            waitForAddMenu: false,
            editCategoryItem: null as any,
            waitForCategory: false,
            waitForAddCategory: false
        };
    },
    created()
    {
        this.eventBus?.on("adminMenuChanged", (event: any) => {
            this.loadMenus();
        });

        this.loadMenus();
    },
    methods: {
        async loadMenus()
        {
            this.menuResponse = await MenuService.getAllFromRestaurant(this.restaurant_id);
            this.waitForMenusResponse = false;

            if(this.menuResponse.status === 1)
            {
                this.categories = RestaurantHelper.createCategoryStrings(this.menuResponse.categories);
            }
        },

        async addMenu(id: number)
        {
            if(!this.waitForAddMenu)
            {
                this.waitForAddMenu = true;
                let response = await MenuService.addMenu(this.restaurant_id, id);
                this.waitForAddMenu = false;

                if(response.status === ResponseTypes.SUCCESS)
                {
                    this.editMenuItem = response.menu;
                }
                else
                {
                    useToast().error(response.msg as string);
                }
            }
        },

        async createCategory()
        {
            if(!this.waitForAddCategory)
            {
                this.waitForAddCategory = true;
                let response = await CategoryService.create(this.restaurant_id);
                if(response.status === ResponseTypes.SUCCESS)
                {
                    this.editCategoryItem = response.category;
                }
                else
                {
                    useToast().error(response.msg as string);
                }


                this.waitForAddCategory = false;
            }
        },

        async EditCategory(id: number)
        {
            if(!this.waitForCategory)
            {
                this.waitForCategory = true;
                this.editCategoryItem = await CategoryService.get(this.restaurant_id, id);
                console.log(this.editCategoryItem);
                this.waitForCategory = false;
            }
        },

        async EditMenu(id: number)
        {
            if(!this.loadEditMenuItem)
            {
                this.editMenuItem = null;
                this.loadEditMenuItem = true;
                // Daten nochmal vom Server laden
                this.editMenuItem = await MenuService.getMenuById(this.restaurant_id, id);
                this.loadEditMenuItem = false;
            }
        },
    },
    computed: {

    }
});
