import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import EventBus from "@/events/EventBus";
import filters from "@/filters";

import Toast, {PluginOptions} from "vue-toastification";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

const options: PluginOptions = {
    timeout: 4000
};

const eventBus = new EventBus();

import "vue-toastification/dist/index.css";
import './assets/sass/core.sass';

/*
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./service-worker-chache.js')
        .then(() => {
            console.log('Service Worker wurde erfolgreich registriert');
        })
        .catch((error) => {
            console.error('Fehler bei der Registrierung des Service Workers:', error);
        });
}
*/
const vue = createApp(App).use(Toast, options).use(vuetify).use(store).use(router);
vue.provide("$eventBus", eventBus);
vue.provide("$filters", filters);
vue.mount('#app');
