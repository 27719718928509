import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { inject } from "vue";
import Store from "@/store/index";
import EventBus from "@/events/EventBus"

import HomeView from '../views/HomeView.vue'

// Restaurant
import SearchView from '../views/Restaurant/SearchView.vue'
import RestaurantView from '../views/Restaurant/RestaurantView.vue'

// Admin
import AdminSettingsView from "../views/Admin/Settings.vue"
import AdminMenuShowMenusView from "../views/Admin/Menu/ShowMenus.vue"

// Auth
import LoginView from '../views/Auth/LoginView.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import ForgotPassword1View from '../views/Auth/ForgotPassword1.vue'
import ResetPasswordView from '../views/Auth/ResetPassword.vue'

import MyOrdersView from '../views/User/MyOrders.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/search',
        name: 'search',
        component: SearchView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            requiresGuest: true
        },
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        meta: {
            requiresGuest: true
        },
    },
    {
        path: '/password',
        name: 'password',
        component: ForgotPassword1View
    },
    {
        path: '/password/reset',
        name: 'password_reset',
        component: ResetPasswordView,
        meta: {
            requiresGuest: true
        },
    },
    {
        path: '/restaurant/:id/:name',
        name: 'restaurant',
        component: RestaurantView
    },

    /* user */
    {
        path: '/user/orders',
        name: 'user@orders',
        component: MyOrdersView,
        meta: {
            requiresAuth: true
        },
    },

    /* admin */
    {
        path: '/admin/settings',
        name: 'admin@settings',
        component: AdminSettingsView,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        },
    },
    {
        path: '/admin/menu',
        name: 'admin@show_menus',
        component: AdminMenuShowMenusView,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        },
    },


    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    if(Store.getters["user/getIsFirstRoute"])
    {
        await Store.dispatch("user/setFirstRoute", to);
    }

    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
    const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
    const isLoggedIn = Store.getters["user/getUser"] != null;
    const isAdmin = isLoggedIn ? Store.getters["user/getUser"].admin.length > 0 : false;

    window.scroll({
        top: 0,
        left: 0
    });

    if(requiresAdmin && !isAdmin)
    {
        next("/");
    }
    else if(requiresAuth && !isLoggedIn)
    {
        next("/login");
    }
    else if(requiresGuest && isLoggedIn)
    {
        next("/");
    }
    else
    {
        next();
    }


});

router.afterEach((to, from) => {
    //
});

export default router
