import Api from "../api";

import Store from "../../store/index";
import AuthResponse from "../classes/AuthResponse";
import ResponseTypes from "@/api/classes/Types";

class AuthService
{
    async updateJwt(jwt: string|null)
    {
        await Store.dispatch('user/setJwt', jwt);
    }

    async login(emailOrNumber: string, password: string) : Promise<AuthResponse>
    {
        let responseItem = new AuthResponse();
        try {
            await Api.post("/auth/login", {
                "email": emailOrNumber,
                "password": password
            }).then(
                async (response) => {
                    await Store.dispatch('user/setUser', response.data.user);
                    await this.updateJwt(response.data.authorisation.token);

                    responseItem.status = ResponseTypes.SUCCESS;
                    responseItem.msg = "Du hast Dich erfolgreich eingeloggt.\r\nWillkommen zurück, " + response.data.user.firstname;
                },
                (error) => {
                    console.log(error);
                    responseItem.status = ResponseTypes.ERROR;
                    responseItem.msg = error.response.data.message;
                }
            );
        }
        catch(e)
        {
            await this.updateJwt(null);
            responseItem.status = ResponseTypes.ERROR;
            responseItem.msg = "Es konnte keine Verbindung zum Server hergestellt werden.";
        }
        return responseItem;
    }

    async logout() : Promise<void>
    {
        try
        {
            let jwt = Store.getters["user/getJwt"];
            await Api.post("/auth/logout", {}, {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }).then(
                async (response) => {
                    await Store.dispatch("user/setUser", null);
                    await this.updateJwt(null);
                },
                async (error) =>
                {
                    await Store.dispatch("user/setUser", null);
                    await this.updateJwt(null);
                }
            )
        }
        catch (e)
        {
            await Store.dispatch("user/setUser", null);
            await this.updateJwt(null);
        }
    }

    async check(): Promise<void>
    {
        let jwt = Store.getters["user/getJwt"];
        try
        {
            await Api.post("/auth/refresh", {}, {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }).then(
                async (response) => {
                    await this.updateJwt(response.data.authorisation.token);
                    await Store.dispatch('user/setUser', response.data.user);
                },
                async (error) => {
                    await this.updateJwt(null);
                }
            );
        }
        catch(e)
        {
            await this.updateJwt(null);
        }
    }

    async register(firstname: string, lastname: string, email: string, phone_number: string, password1: string, password2: string) : Promise<AuthResponse>
    {
        let responseItem = new AuthResponse();

        try
        {
            await Api.post("/auth/register", {
                firstname: firstname,
                lastname: lastname,
                email: email,
                phone_number: phone_number,
                password1: password1,
                password2: password2
            }).then(
                async (response) => {
                    responseItem.status = ResponseTypes.SUCCESS;
                    responseItem.msg = response.data.message;

                    await Store.dispatch('user/setUser', response.data.user);
                    await this.updateJwt(response.data.authorisation.token);
                },
                async (error) => {
                    responseItem.msg = error.response.data.message;
                }
            )
        }
        catch (e)
        {
            responseItem.status = ResponseTypes.ERROR;
            responseItem.msg = "Es konnte keine Verbindung zum Server hergestellt werden.";
        }

        return responseItem;
    }
}

export default new AuthService();